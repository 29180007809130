.main-wrapper {
    display : flex;
    justify-content: center;
    background-color: rgb(255, 254, 250);
    flex : 1;
}

.main-container {
    /* width: 100%; */
    max-width: 800px;
    /* min-height: 100vh; */
    /* align-items: center; */
    
}

.main-main {
    /* display: flex; */
    /* background-color: gray; */
    margin : 10px 0;
    /* padding : 10px; */
    font-size: calc(10px + 2vmin);
    /* flex-direction: column; */
    /* display: flex; */


}