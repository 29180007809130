.voca-card {
    display: flex;
    align-items: center;
    background-color: rgb(255, 252, 246);
    padding : 10px;
    margin: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgb(212, 212, 212);
    font-size: calc(12px + 1vmin);
}

.voca-card-name {
    min-width: 20%;
    padding : 0 15px;
    justify-content: center;
    font-weight:bold;
    display: inline-block;
    color : chocolate;
    /* font-size: 2rem; */
    font-size: calc(12px + 1vmin);

}

.voca-card-content {
    font-size: calc(10px + 1vmin);

}

.voca-card-class {
    display: inline-block;
    background-color: khaki;
    padding: 1px 3px;
    margin: 10px 2px;
    border-radius: 4px;
    font-size: calc(6px + 1vmin);
    /* width : 20px; */
    /* height : 20px; */
    font-weight: bold;
    color: chocolate;
}

.vertical-rule {
    background-color: rgb(243, 224, 211);
    display: inline-block;
    margin : 10px;
    width: 2px;
    height: 40px;
}