.login-container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-container {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 200px;
    background-color: rgb(255, 252, 246);
    padding : 10px;
    margin: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgb(212, 212, 212);
    font-size: calc(12px + 1vmin);
}
label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    width: 100%;
}
input {
    box-sizing: border-box;
    width: 100%;
    
    height: 40px;
    font-size: 1rem;
    padding : 0 10px;
    
}
a {
    display: block;
}
.input-wrapper {
    margin: 10px;
    
    /* display: flex; */
    /* justify-content: center; */
}

