.header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top : 0;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    z-index: 1000;
    background-color: rgb(255, 220, 154);
    /* opacity: 95%; */
}

.header-container {
    /* position: absolute; */
    /* top : 0; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 100%;
    max-width: 800px;
    /* margin : 10px 0; */
    
    height: 50px;
    /* overflow: hidden; */
    /* visibility: visible; */
    /* overflow: auto; */
    /* justify-content: right; */
}
.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    
    
    
}
.header-nav a {
    margin-bottom: 10px;
    padding : 20px;
    display: block;
    text-decoration: none;
    font-weight: bold;
    color : chocolate;
}
.header-nav a:hover {
    background-color: rgb(252, 206, 121);;
}
button {
    /* display: inline-block; */
}
.Logo{
    /* display: inline-block; */
    color : rgb(175, 62, 47);
    font-size : 20px;
}