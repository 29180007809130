.footer-wrapper {
    /* position :relative; */
    /* bottom : 0; */

    display: flex;
    justify-content: center;
    background-color: #f8f8f7;
}

.footer-container {
    /* width: 100%; */
    /* max-width : 800px; */
    /* margin : 20px; */
    /* border : 40px; */
    /* padding : 0 10px; */
}